.cardMain {
  box-shadow: 3px 5px 10px 2px rgba(154, 154, 154, 0.27);
  width: 250px;
  margin-right: 20px;
  height: 400px;
  border-radius: 15px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  flex-direction: column;

  &:hover {
    box-shadow: 3px 17px 13px 2px rgba(154, 154, 154, 0.27);
  }

  .content {
    flex: 0.75;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 35px;
      font-weight: 600;
    }
  }

  .footerCard {
    flex: 0.25;
    width: 100%;
    // background-color: aquamarine;
    border-radius: 0 0 15px 15px;
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .cardMain {
    width: 300px;
    height: 200px;
    flex-direction: row;
    margin-right: 0;
    margin-bottom: 20px;

    .footerCard {
      height: 100%;
      border-radius: 0 15px 15px 0;
      flex: 0.3;
    }
  }
}
