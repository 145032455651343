.homeMain {
  width: 100%;
  background-color: #eef0f3;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .cardHolder {
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;

    @media (min-width: 320px) and (max-width: 1024px) {
      width: 100%;
      min-height: 100vh;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
