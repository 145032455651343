.admTableCell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  p {
    margin: 0;
  }
}
.tableIcon {
  border-radius: 500px;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0 5px;
  transition: all ease-in-out 0.2s;
}
.tableIcon:hover {
  background-color: rgb(230, 230, 230);
}
.ant-tabs-nav-list {
  width: 100%;
  overflow: hidden;
  min-height: 60px;
}
.ant-tabs-tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-tabs-ink-bar {
  width: 100%;
}
.ant-card,
.ant-card-actions {
  border-radius: 10px;
}
