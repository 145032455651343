.subLogsCont,
.subLogs {
  transition: all ease-in-out 0.2s;
}

.subLogsCont-open {
  height: 330px;
  .subLogs {
    height: 300px;
  }
}
.subLogsCont-closed {
  height: 0;
  .subLogs {
    height: 0;
    padding: 0;
  }
}
.subLogs {
  background-color: #0f0f0f;
  border-radius: 5px;
  color: #f0f0f0;
  padding: 5px 15px;
  padding-right: 4px;
  overflow-y: auto;
  .subLog {
    display: flex;
    span,
    p {
      margin: 0;
    }
    .subLog-stsGreen {
      color: #00a100;
    }
    .subLog-stsRed {
      color: tomato;
    }
    p {
      margin-left: 5px;
    }
  }
}
