.admLayout {
  min-height: 100vh;
}
.admContent {
  padding: 50px;
  padding-top: 30px;

  @media (max-width: 660px) {
    padding: 20px;
  }
}
.admHeader {
  .admHeader-row {
    display: flex;
    align-items: center;
    color: white;
    h3,
    p {
      margin: 0;
      padding: 0;
    }
    h3 {
      color: rgb(132, 194, 255);
      font-size: 20px;
    }
    span {
      margin: 0 10px;
      font-size: 20px;
      color: rgb(180, 180, 180);
    }
  }
}
